<template>
  <div>
    <b-row>
      <table class="table table-sm ">
        <thead>
          <tr>
            <th scope="col" class="table-primary">Price Calculation</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col cols="12" sm="12" md="12" xl="6">
        <table class="table border table-sm">
          <tr>
            <th scope="col" class="text-right">Vehicle Listing Price</th>
            <td scope="col" v-if="saleQuoteData.listingPrice != null">{{ formatPrice(saleQuoteData.listingPrice) }}</td>
            <td scope="col" colspan="2" v-else>{{ formatPrice(0) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Vehicle Leased Price</td>
            <td>
              <cleave style="height:2.142rem" v-model="saleQuoteData.leasedPrice" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Cash Down</td>
            <td>
              <cleave style="height:2.142rem" v-model="saleQuoteData.cashDown" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Trade as Cash Down</th>
            <td scope="col">
              {{ formatPrice(saleQuoteData.tradeCashDown ? saleQuoteData.tradeCashDown : 0, 2) }}
            </td>
            <td></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Net Cap</th>
            <td scope="col">
              {{ formatPrice(netCap, 2) }}
            </td>
            <td></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Residual <span class="text-danger"> </span></td>
            <td>
              <cleave style="height:2.142rem" v-model="saleQuoteData.residual" class="form-control" :raw="true" :options="options.number" />
            </td>
            <td></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Amount Amortized</th>
            <td scope="col">
              {{ formatPrice(amortized, 2) }}
            </td>
            <td></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Security Deposit</td>

            <td><cleave id="options" style="height:2.142rem" v-model="saleQuoteData.securityDeposit" class="form-control" :raw="true" :options="options.number" /></td>
            <td></td>
          </tr>

          <tr>
            <td scope="col" style="width: 50%;" class="text-right">G.S.T Rate & Amount on Vehicle</td>
            <td style="width: 30%;"><cleave id="options" style="height:2.142rem; " v-model="saleQuoteData.gstRateVehicle" class="form-control" :raw="true" :options="options.percent" /></td>
            <td scope="col" style="width: 40%;" class="text-dark font-weight-bold">{{ formatPrice(gstAmountOnVehicle, 2) }}</td>
          </tr>

          <tr>
            <td scope="col" class="text-right">P.S.T Rate & Amount on Vehicle</td>
            <td><cleave id="options" style="height:2.142rem" v-model="saleQuoteData.pstRateVehicle" class="form-control" :raw="true" :options="options.percent" /></td>
            <td scope="col" class="text-dark font-weight-bold">{{ formatPrice(pstAmountOnVehicle, 2) }}</td>
          </tr>
        </table>
      </b-col>

      <b-col cols="12" sm="12" md="12" xl="6">
        <table class="table border table-sm">
          <tr>
            <td scope="col" style="width: 50%;" class="text-right font-weight-bold">Due On Delivery</td>
            <td scope="col" style="width: 30%;">{{ formatPrice(dueDelivery, 2) }}</td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <td scope="col" class="text-right font-weight-bold">Term</td>
            <td><cleave id="options" style="height:2.142rem;" v-model="saleQuoteData.term" class="form-control" :raw="true" :options="options.number" /></td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Rate</td>
            <td><cleave id="options" style="height:2.142rem" v-model="saleQuoteData.rate" class="form-control" :raw="true" :options="options.percent" /></td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Base Payment</th>
            <td scope="col">
              {{ formatPrice(basePayment, 2) }}
            </td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <td scope="col" class="text-right">Monthly Admin</td>

            <td><cleave id="options" style="height:2.142rem" v-model="saleQuoteData.monthlyAdmin" class="form-control" :raw="true" :options="options.number" /></td>
            <td scope="col" style="width: 20%;"></td>
          </tr>

          <tr>
            <th scope="col" class="text-right">Net Payment Before Tax</th>
            <td scope="col">
              {{ formatPrice(netPayment, 2) }}
            </td>
            <td scope="col" style="width: 20%;"></td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <b-row>
      <table class="table border  text-center table-condensed table-sm mt-1">
        <thead>
          <tr>
            <th scope="col" colspan="2" class="text-capitalize">Additional Charges</th>
            <th scope="col" style="width:20%" class="text-capitalize">Price</th>

            <th scope="col" class="text-capitalize">#</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="2">
              <!-- <b-form-input id="options" placeholder="Additional Sale Options" size="sm" v-model="priceCalculationTemp.pcOptions" type="text" /> -->
              <b-form-group label-for="additional-charges">
                <v-select
                  v-model="priceCalculationTemp.pcOptions"
                  :taggable="true"
                  class="mt-1"
                  :loading="dropdownLoading"
                  @option:created="createNewDropdownValue"
                  @option:selected="selectedDropdownValue"
                  :value="additionalSelected"
                  :options="additionalChargeOptions"
                  :reduce="(val) => val.title"
                  label="title"
                  :clearable="true"
                >
                </v-select>
              </b-form-group>
            </td>
            <td>
              <cleave id="pcPrice" placeholder="Option Price" v-model="priceCalculationTemp.pcPrice" class="form-control" :raw="true" :options="options.number" />
            </td>

            <td>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="priceCalculationTemp.pcOptions == null || priceCalculationTemp.pcOptions == '' || priceCalculationTemp.pcPrice == null || priceCalculationTemp.pcPrice == ''" @click="priceCalculation()" class="btn-icon" variant="primary">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </td>
          </tr>

          <tr style="text-align:center" v-for="(item, index) in saleQuoteData.priceCalculationValues" :key="index">
            <td style="text-align:left">{{ item.pcOptions }}</td>
            <td>{{ formatPrice(item.pcPrice) }}</td>

            <td class="text-center"><feather-icon :id="`option-row-${item.id}`" icon="TrashIcon" @click="removeAdditionalItem(index)" size="16" class="cursor-pointer mx-1" /></td>
          </tr>
          <tr>
            <td class="bg-secondary" colspan="4"></td>
          </tr>

          <tr>
            <th colspan="2" class="text-right">Additional Charges Sub Total</th>
            <td>{{ formatPrice(additionaloptionsPrice) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BButton } from 'bootstrap-vue';
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import vSelect from 'vue-select';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,

    vSelect,
    VueNumericInput,
    ToastificationContent,
    Cleave,
  },
  directives: {
    Ripple,
  },

  props: {
    saleQuoteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      additionalChargeOptions: [],
      additionalSelected: null,

      dropdownLoading: false,
      discountRateVal: 0,
      userData: JSON.parse(localStorage.getItem('userData')),

      priceCalculationTemp: {
        pcOptions: null,
        pcPrice: null,
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {},

  computed: {
    netCap() {
      this.saleQuoteData.netCap = this.saleQuoteData.leasedPrice - this.saleQuoteData.cashDown - this.saleQuoteData.tradeCashDown + Number(this.additionaloptionsPrice);
      return Number(this.saleQuoteData.netCap ?? 0);
    },

    amortized() {
      this.saleQuoteData.amortized = this.saleQuoteData.netCap - this.saleQuoteData.residual;
      return Number(this.saleQuoteData.amortized ?? 0);
    },

    dueDelivery() {
      this.saleQuoteData.dueDelivery = Number(this.saleQuoteData.cashDown) + Number(this.saleQuoteData.securityDeposit) + Number(this.saleQuoteData.gstAmountVehicle) + Number(this.saleQuoteData.pstAmountVehicle);
      return Number(this.saleQuoteData.dueDelivery ?? 0);
    },
    basePayment() {
      let r = Number(this.saleQuoteData.rate) / 100 / 12;
      let pv = Number(this.saleQuoteData.netCap) * -1;
      let n = Number(this.saleQuoteData.term);
      let fv = Number(this.saleQuoteData.residual);
      this.saleQuoteData.basePayment = this.PMT(r, n, pv, fv, 1);
      return Number(this.saleQuoteData.basePayment ? this.saleQuoteData.basePayment : 0);
    },

    netPayment() {
      this.saleQuoteData.netPayment = Number(this.saleQuoteData.basePayment ? this.saleQuoteData.basePayment : 0) + Number(this.saleQuoteData.monthlyAdmin ? this.saleQuoteData.monthlyAdmin : 0);

      return Number(this.saleQuoteData.netPayment ? this.saleQuoteData.netPayment : 0);
    },

    priceDifference() {
      var tempCustomerTrade = Number(this.saleQuoteData.creditIssued - this.saleQuoteData.sellingPrice - this.saleQuoteData.deductionCredit);
      if (tempCustomerTrade <= 0) {
        return Number((this.saleQuoteData.sellingPrice ?? 0) - (this.saleQuoteData.creditIssued ?? 0));
      } else {
        return 0;
      }
    },

    sumBeforeTax() {
      // this.saleQuoteData.lessDepositPayable = 0;
      // this.saleQuoteData.lessAdditionalCredit = 0;

      var total = 0;

      this.saleQuoteData.trades.forEach((element) => {
        total += Number(element.beforeTax);
      });

      this.saleQuoteData.winTrades.forEach((element) => {
        total += Number(element.beforeTax);
      });

      return Number(total);
    },

    customerTradeEquity() {
      if (this.saleQuoteData.creditIssued > this.saleQuoteData.sellingPrice) {
        return this.saleQuoteData.creditIssued - this.saleQuoteData.sellingPrice - this.saleQuoteData.deductionCredit - this.saleQuoteData.lessAdditionalCredit;
      } else {
        this.saleQuoteData.deductionCredit = null;
        this.saleQuoteData.lessAdditionalCredit = null;
        return 0;
      }
    },

    gstAmountOnVehicle() {
      this.saleQuoteData.gstAmountVehicle = (this.saleQuoteData.cashDown + 0) * (this.saleQuoteData.gstRateVehicle / 1000);
      return this.saleQuoteData.gstAmountVehicle;
    },

    pstAmountOnVehicle() {
      this.saleQuoteData.pstAmountVehicle = (this.saleQuoteData.cashDown + 0) * (this.saleQuoteData.pstRateVehicle / 1000);
      return this.saleQuoteData.pstAmountVehicle;
    },

    additionaloptionsPrice() {
      var total = 0;
      this.saleQuoteData.deductionCredit = 0;
      this.saleQuoteData.priceCalculationValues.forEach((element) => {
        total = Number(total) + Number(element.pcPrice);
      });

      return total;
    },
  },

  created() {
    this.getAllDropDown();
  },

  methods: {
    PMT(ir, np, pv, fv, type) {
      /*
       * ir   - interest rate per month
       * np   - number of periods (months)
       * pv   - present value
       * fv   - future value
       * type - when the payments are due:
       *        0: end of the period, e.g. end of month (default)
       *        1: beginning of period
       */
      var pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt;
    },

    createNewDropdownValue(val) {
      //
      this.dropdownLoading = true;

      if (this.additionalChargeOptions.find((x) => x.title.toUpperCase() == val.title.toUpperCase()) == undefined) {
        store
          .dispatch('testlease/saveAdditional', {
            title: val.title.toUpperCase().trim(),
          })
          .then((response) => {
            this.additionalChargeOptions.unshift(response.data);
            // this.priceCalculationTemp.pcOptions = response.data;

            // this.additionalChargeOptions = response.data;
            // this.additionalChargeOptions.id = response.data.filter((x) => x.title.toUpperCase() == val.title.toUpperCase())[0];
            // // this.toastMessage('Accessorie Options Name');
            this.selectedDropdownValue(response.data);
            this.dropdownLoading = false;
          });
      } else {
        this.dropdownLoading = false;
      }
    },

    getAllDropDown() {
      this.formShow = true;
      store
        .dispatch('testlease/getAllDropDowns')
        .then((response) => {
          this.additionalChargeOptions = response.data.additionalCharges;
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching dropdowns list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    selectedDropdownValue(val) {
      //orhan

      this.additionalChargeOptions.id = this.additionalChargeOptions.filter((x) => x.title.toUpperCase() == val.title.toUpperCase())[0];
    },

    priceCalculation() {
      this.saleQuoteData.priceCalculationValues.push(this.priceCalculationTemp);
      this.priceCalculationTemp = {
        pcOptions: null,
        pcPrice: null,
      };
    },

    rateCalc() {
      this.saleQuoteData.sellingPrice = this.saleQuoteData.listingPrice - (this.saleQuoteData.listingPrice * this.saleQuoteData.discountRate) / 100;
    },

    sellingPriceCalc() {
      this.saleQuoteData.discountRate = 100 - (100 * this.saleQuoteData.sellingPrice) / this.saleQuoteData.listingPrice;
    },

    removeAdditionalItem(e) {
      this.$delete(this.saleQuoteData.priceCalculationValues, e);
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  },
};
</script>
