import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('customer', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    addCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('customer', customerData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    savePurchaseFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('savePurchaseFile', data)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    addInventory(ctx, inventoryData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventory', inventoryData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //GetDropCustomer

    getCustomerDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('customerDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCustomerDropDownId(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`customerDrop/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //GetDropInventory

    getInventoryDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryTradeDrop() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryTradeDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryDropDownId(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`inventoryDrop/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTradeInventorySelectId(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`tradeInventorySelectId/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //AllDropDown

    getAllDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('alldropdown')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    purchaseAllDropDown() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchaseAllDropDown')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMsrpOption() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('msrpoption')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMsrpPrice() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('msrpprice')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMsrpOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('msrpoption', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveQuote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('savePurchaseQuote', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateQuote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updatePurchaseQuote/${data.id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllQuote(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listPurchaseQuotes', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllArchiveQuote(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listArchivePurchaseQuotes', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`singlePurchaseQuote/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPreviewQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`previewPurchaseQuote/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getArchivePreviewQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`previewArchivePurchaseQuote/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    imageUpload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('quotegallery', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadPurchase', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfArchiveDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfArchivedownloadPurchase', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMsrpPrice(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('msrpprice', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveAccessioreOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('accessioreoption', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccessioreOption() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('accessioreoption')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccessiorePrice() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('accessioreprice')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getModelByMake(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('model/' + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //getModelByModel

    getTrimByModel(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('trim/' + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Add inventory
    addInventory(ctx, inventoryData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventory', inventoryData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    sendToArchive(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('sendToArchive', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveModelYear(ctx, modelYear) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('modelYear', { value: modelYear })

          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMakes(ctx, make) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('make', { value: make })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveModel(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('model', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    nextIdQuotes() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('nextPurchaseId')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveDrivetrain(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('drivetrain', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveTrim(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('trim', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveTransmmissionType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('transmmissiontype', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveBodyType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('body', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveFuelType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('fueltype', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveExtColour(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('extcolour', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveIntColour(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('intcolour', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveVinNo(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('vinno', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveOrigenalMarket(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('origenalmarketspecs', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveBaseMsrpCurrency(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('basemsrpcurrency', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //add purchase
    addPurchase(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('purchase', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPurchases(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('purchase', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getPurchaseByIdPreview(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`purchasePreview/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPurchaseById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`purchase/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updatePurchaseStatus(ctx, val) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`inventoryPurchaseStatus/${val.id}`, val)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    getPurchaseDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('showTradeInDropAll')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    vinNoCheck(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('vinNoPurchaseCheck', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateQuoteStatus(ctx, val) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`purchasequoteStatus/${val.id}`, val)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchPurchaseColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchase_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    savePayment(ctx, paymentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('savePayment', paymentData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
  },
};
